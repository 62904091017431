.tsc-week {
  flex-grow: 1;
  .calendar-helpText > p {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .tsc-week {
    flex-direction: column;
  }
}

.form-container .tsc-week-days-container {
  display: flex;
  .tsc-week-days {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 1100px) {
  .form-container .tsc-week {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: inherit;
  }
}
