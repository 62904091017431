@mixin timeslotSelected {
  // background-color: $timeslot-selected-bg-color;
  // color: $timeslot-selected-color;
}

.tsc-timeslot {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1em;
  font-size: 0.9em;
  background-color: $timeslot-default-bg-color;
  transition: background-color 0.5s, color 0.5s;
  //, box-shadow cubic-bezier(.25,.8,.25,1) 0.3s;
  border-radius: $timeslot-border-radius;
  text-align: center;
  margin: $timeslot-margin;
  color: $timeslot-default-color;
  border: 1px solid #ccc;
  //box-shadow: 0 0.0625em 0.188em rgba(0,0,0,0.12), 0 0.0625em 0.125em rgba(0,0,0,0.24);

  &:not(.tsc-timeslot--disabled) {
    cursor: pointer;
    &:hover{
      @include timeslotSelected;
      //box-shadow: 0 0.188em 0.563em rgba(0,0,0,0.25), 0 0.125em 0.125em rgba(0,0,0,0.22);
    }
  }
}

.tsc-timeslot--selected {
  @include timeslotSelected;
  border: 0px;
}

.tsc-timeslot--disabled {
  background-color: $timeslot-disabled-bg-color;
  color: $timeslot-disabled-color;
  border: 0px;
}

.tsc-timeslot--hidden {
  visibility: hidden;

  @media (max-width: 1100px) {
    display: none;
  }
}

.form-container .tsc-timeslot {
  flex: 0 0 24%;
  margin: 0.5rem 0;
  border-radius: 0;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .form-container .tsc-timeslot {
    flex: 0 0 35%;
  }
}

.tsc-timeslot:not(.tsc-timeslot--disabled):hover {
   color: #dddddd;
}
