@import 'styles/colors';
@import 'styles/variables';
@import 'styles/fontfaces';
@import 'styles/customization/restyling.scss';
@import 'styles/select';

html,
body,
#root {
  height: 100%;
}

body {
  font-weight: $LIGHT;
  color: $DEFAULT_FONT_COLOR;
  margin: 0px !important;
  background-color: $BACKGROUND_PAGE;
}
.login-icon {
  max-width: $LOGO_WIDTH_HUGE;
  max-height: 100px;
}

h1 {
  margin: 30px 0px 0px 0px;
}

.wrapper {
  // HEADER STYLE
  .header {
    position: relative;
    font-size: 1rem;
    font-style: normal;
    line-height: 1rem;
    padding: 1rem;
    font-weight: bold;
    text-align: center;

    &-language-switcher {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
  // STEPPER STYLE
  .form-stepper {
    padding: 0;
    .MuiStep-root:first-child {
      padding-left: 0;
    }
    .MuiStep-root:last-child {
      padding-right: 0;
    }
    .MuiStepLabel-iconContainer {
      padding: 0;
    }
    .MuiStepConnector-active .MuiStepConnector-line,
    .MuiStepConnector-completed .MuiStepConnector-line {
      border-color: $COLOR_PRIMARY;
      border-width: 2px;
    }
  }

  //footer
  .footer {
    border-top: 1px solid $DEFAULT_BORDER_COLOR;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
    .footer-links {
      display: flex;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1rem;
      > a {
        padding: 0.25rem;
      }
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      padding: 1rem 2rem;
      .footer-links {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 1100px) {
      padding: 1rem 4rem;
    }
    @media screen and (max-width: 480px) {
      padding: 1rem 2rem;
    }
  }
}

.wrapper-form {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}
