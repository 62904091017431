@import "styles/colors";

// FONTS

@font-face {
  font-family: SegoeUILight;
  src: local("Segoe UI Light"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf)
      format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI Semilight"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf)
      format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf)
      format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI Bold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf)
      format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI Semibold"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2)
      format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff)
      format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf)
      format("truetype");
  font-weight: 700;
}

// GENERAL
body > * {
  font-family: SegoeUI, sans-serif;
}

a {
  color: $COLOR_PRIMARY;
}

.form-container .error {
  font-size: 0.7rem;
  color: $ERROR_COLOR;
}

.form-container {
  padding: 1rem 6rem;
  min-height: 80vh;
}

.form-container .note label span {
  font-size: 0.7rem;
  line-height: 1.3;
  margin: 0;
  padding: 0 0.3rem 0 0 !important;
}

.form-container .note label {
  margin: 1.5rem 0 0 0;
}

// BUTTON STYLE
.form-container .buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;
  button {
    margin: 0;
    + button {
      margin-left: 1rem;
    }
  }
}

.form-container .required-info textarea {
  padding-left: 0.75rem !important;
  padding-bottom: 0.5rem !important;
}

 // TODO: questi stili non dovrebbero stare qui, e non dovrebbero essere referenziati le classi di material in questa maniera
 .form-container .basic-information {
   .MuiInputLabel-root {
     color: $GREY !important;
   }
  .MuiIconButton-label > input ~ div {
    color: $GREY;
  }
 }
 .form-container .MuiGrid-root {
   font-size: .8rem
 }


.form-container .resume {
  background-color: rgba($LIGHT_GREY_BACKGROUND, 0.38);
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  margin: 0 0 2rem 0;
  box-sizing: border-box;
  width: 100%;
}
.form-container .resume > div {
  font-size: 1rem;
  font-weight: 100;
  flex: 0 0 100%;
  margin: 0.3rem 0 0 0;
  display: flex;
  justify-content: space-between;
}
.form-container .resume > div b {
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .form-container {
    padding: 1rem 4rem;
  }
  .footer {
    padding: 1rem 4rem;
  }
}


@media screen and (max-width: 480px) {
  .form-container {
    padding: 1rem 2rem;
  }
  .form-container .buttons-container {
    > button {
      flex-grow: 1;
    }
  }
}

.calendar-container,
.calendar-loader {
  min-height: 365px;
  padding: 24px 0;
}

.calendar-not-visible {
  padding-top: 24px;
}

@media screen and (max-width: 1100px) {
  .calendar-container,
  .calendar-loader,
  .calendar-not-visible {
    height: unset;
    margin-bottom: 10px;
  }
}

.calendar-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
