@import 'styles/fontfaces';

$DEFAULT_FONT: var(--default-font, inherit);
$BREAKPOINT_SMARTPHONE: var(--breakpoint-smartphone, 599px);
$BREAKPOINT_MD: var(--breakpoint-md, 959px);
$LOGO_WIDTH_HUGE: var(--logo-width-huge, 180px);
$LIGHT: var(--light-font, 300);
$MEDIUM: var(--medium-font, 500);
$FOOTER_LOGO_HEIGHT: var(--footer-logo-height, 2rem); 

:export {
  DEFAULT_FONT: $DEFAULT_FONT;
  BREAKPOINT_SMARTPHONE: $BREAKPOINT_SMARTPHONE;
  BREAKPOINT_MD: $BREAKPOINT_MD;
  LOGO_WIDTH_HUGE: $LOGO_WIDTH_HUGE;
  LIGHT: $LIGHT;
  MEDIUM: $MEDIUM;
  FOOTER_LOGO_HEIGHT: $FOOTER_LOGO_HEIGHT;
}
