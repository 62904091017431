@import 'styles/colors.scss';

.information-helpText {
  display: flex;
  justify-content: flex-end;
  .word-counter {
    justify-content: flex-end;
    color: $DEFAULT_TEXT;
    font-size: 0.8rem;
  }
}

.error {
  .information-helpText {
    justify-content: space-between;
  }
}

.note {
  &.error {
    display: flex;
    flex-direction: column;
  }
  .MuiSvgIcon-root {
    color: #989898;
  }
}

.tsc-timeslot--disabled {
  background-color: $DEFAULT_BACKGROUND;
  border: 1px solid $DEFAULT_BORDER_COLOR;
}

.missing-slot-helpText {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  color: $DEFAULT_TEXT;
  font-size: 0.8rem;
}

.form-container .note label {
  margin: 0 !important;
}
