.tsc-day {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tsc-day__title {
  color: $day-default-color;
  text-align: center;
  font-weight: 700;
  padding: 1em;
  text-transform: uppercase;
}

.form-container .tsc-dayTitle {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 70px;
  margin: 0;
  padding: 0.5rem;
  + .tsc-dayTitle {
    margin-bottom: 0.5rem;
  }
}

.form-container .tsc-dayTitle > * {
  width: 100%;
}

.form-container .tsc-dayTitle .tsc-day_number {
  font-size: 1.3rem;
  font-weight: 700;
}

.form-container .tsc-dayTitle {
  cursor: pointer;
  user-select: none;
  transition: background 300ms ease;
  &:not([class*="disabled"]):hover {
    background: $LIGHT_GREY;
  }
  &.tsc-dayTitle--selected {
    background: $COLOR_PRIMARY;
    color: white;
  }
  &.tsc-dayTitle--disabled {
    color: $DISABLED_GREY;
    cursor: not-allowed;
  }
}

.form-container .tsc-timeslot--selected {
  background: $COLOR_PRIMARY;
  color: white;
}

.form-container .tsc-day {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 1100px) {
  .form-container .tsc-day {
    top: 13rem;
  }
  .form-container .tsc-dayTitle {
    flex: 0 0 18%;
  }
}

@media screen and (max-width: 320px) {
  .form-container .tsc-day {
    top: 18rem;
  }
}