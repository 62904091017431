.tsc-month {
  display: flex;
  flex-direction: column;
  background: $month-bg-color;
}

.tsc-month__actions {
  display: flex
}

.tsc-month__action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
}

.tsc-month__action-element {
  font-size: 1.5em;
  padding: 0 1em;
  cursor: pointer;
}

.tsc-month__action-title {
  flex-grow: 2;
  padding: 1em;
}

.form-container .tsc-month {
  background-color: transparent;
}

.form-container .tsc-month {
  flex-grow: 1;
  .tsc-month__actions {
    flex-grow: 1;
  }
}

.form-container .tsc-month__action {
  margin: 0;
  padding: 0 0.7rem;
}

.tsc-month__action.disabled {
  color: $DISABLED_GREY !important;
}
