@import "styles/colors";
.tsc-calendar {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: $color-main-black;
  background: $calendar-bg-color;
  padding: 0.8em;
}

.tsc-calendar__actions {
  display: flex;
  flex: 1;
  border-bottom: 0.075em solid darken($color-clouds, 5%);
}

.tsc-calendar__action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: $color-main-black;
  text-align: center;
}

.tsc-calendar__action-element {
  font-size: 1.5em;
  padding: 0 1em;
  cursor: pointer;
}

.tsc-calendar__action-title {
  flex-grow: 2;
  padding: 1em;
  text-transform: uppercase;
}

.form-container .tsc-calendar {
  background-color: rgba($LIGHT_GREY_BACKGROUND, 0.3);
  /* min-height: 300px; */
  position: relative;
  // margin-top: 1.5rem;
  padding: 1.5rem;
}